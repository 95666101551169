<template>
    <div class="status-light" :style="lightStyle"></div>
</template>

<script>
export default {
    props: ['styleDatas', 'element'],
    computed: {
        lightStyle() {
            let style = this.element.style || {};
            if (style.lightBackground === 'icon' && style.lightBackgroundImage) {
                return {
                    backgroundImage: `url(${style.lightBackgroundImage})`,
                    backgroundColor: 'transparent'
                };
            } else {
                return {
                    backgroundImage: '',
                    backgroundColor: style.lightBackgroundColor
                };
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.status-light {
    background: #409eff;
    border-radius: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}
</style>